<template>
  <v-dialog v-model="dialog" max-width="560" persistent>
    <v-card>
      <v-card-title>
        {{ $t("elearning.copy_elearning") }}
        {{ lesson }}
        {{ $t("app.to") }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModalHandler">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-form ref="form" v-model="valid">
          <v-row align="center">
            <FilterSchoolYear
              :triggerChange="dialog"
              :itemDisable="itemDisable"
              :dataFilter="dataFilter"
              isVertical
              widthHorizontal
              multipleClass
              @handler="getFilter"
            />
          </v-row>
          <v-row no-gutters class="mt-3">
            <v-alert
              v-if="searchLessonFlag"
              color="warning"
              class="caption white--text mb-0"
              dense
            >
              {{ $t("elearning.subject_not_found") }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="searchLessonLoading"
              depressed
              @click="searchLesson()"
              >{{ $t("app.search") }}</v-btn
            >
          </v-row>
        </v-form>

        <v-divider class="my-4"></v-divider>

        <v-sheet v-if="lessonList.length > 0" class="mt-4">
          <v-card>
            <v-data-table
              v-model="selectedLesson"
              :headers="headersLesson"
              :items="lessonList"
              item-key="idx"
              show-select
            >
              <template v-slot:item.lesson="{ item }">
                {{ item.lesson }} {{ item.sub }}
              </template>
            </v-data-table>
          </v-card>
          <v-row no-gutters class="mt-2">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid || selectedLesson.length == 0"
              :loading="loadingSubmit"
              color="primary"
              depressed
              @click="submit"
              class="mt-3"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getSubjectsList } from "@/api/admin/academic/subjects";

import { copy_to_mapel } from "@/api/admin/academic/eLearning";
import FilterSchoolYear from "@/views/admin/components/filterSchooYear";

export default {
  components: { FilterSchoolYear },
  props: {
    dialog: Boolean,
    filterList: Object,
    lesson: String,
    lessonId: [String, Number],
    selectedLessonMaster: [String, Number],
    filter: Object,
    closeModal: Function,
    getList: Function
  },
  data() {
    return {
      valid: false,
      classList: [],
      lessonList: [],
      selectedLesson: [],
      loadingClass: false,
      searchLessonFlag: false,
      searchLessonLoading: false,
      loadingSubmit: false,
      headersLesson: [
        {
          text: this.$i18n.t("elearning.table.subject"),
          align: "left",
          value: "lesson"
        },
        {
          text: this.$i18n.t("elearning.table.grade"),
          sortable: false,
          value: "grade"
        },
        {
          text: this.$i18n.t("elearning.table.class"),
          sortable: false,
          value: "class_name"
        }
      ],
      form: {
        lesson: this.lessonId,
        school_year: null,
        grade: null,
        class: null
      },
      itemDisable: {
        year: "",
        grade: "",
        class: ""
      },
      dataFilter: {
        year: "",
        grade: "",
        class: ""
      }
    };
  },
  watch: {
    dialog() {
      this.itemDisable = {};
      this.dataFilter = {};
      this.$nextTick(() => {
        this.itemDisable = this.filter;
        this.dataFilter.year = this.filter.school_year;
        this.dataFilter.grade = this.filter.grade;
      });
    }
  },
  methods: {
    getFilter(res) {
      if (res) {
        switch (res.type) {
          case "school_year":
            this.form.school_year = res.data.id;
            break;
          case "select_grade":
            this.form.grade = res.data;
            break;
          default:
            this.form.class = res.data;
            break;
        }
      }
    },
    closeModalHandler() {
      this.$refs.form.reset();
      this.searchLessonFlag = false;
      this.lessonList = [];
      this.closeModal();
    },
    searchLesson() {
      this.searchLessonLoading = true;
      this.searchLessonFlag = false;
      getSubjectsList({
        filter: {
          classroom: this.form.class,
          school_year: [this.form.school_year],
          lesson: this.lessonId
        }
      })
        .then(res => {
          if (res.data.code) {
            this.lessonList = [];
            const newData = [];

            res.data.data.map((row, idx) => {
              if (row.lesson_master == this.selectedLessonMaster) {
                // const getId = newData
                //   .map(r => r.id_lesson)
                //   .indexOf(row.id_lesson);

                // console.log(getId);
                // if (getId)
                newData.push({ idx: idx, ...row });
              }
            });

            this.$nextTick(() => {
              this.lessonList = newData;
              if (newData.length == 0) this.searchLessonFlag = true;
            });
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.searchLessonLoading = false;
        })
        .catch(() => {
          this.searchLessonLoading = false;
        });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.selectedLesson.length > 0) {
          this.loadingSubmit = true;
          const getClassAndLesson = [];
          await this.selectedLesson.map(r => {
            getClassAndLesson.push({ class: r.classroom, lesson: r.id_lesson });
          });

          const data = {
            elearning: [],
            class_and_lesson: getClassAndLesson,
            from_lesson: this.lessonId,
            from_class: this.filter.class,
            type: "LESSON"
          };

          copy_to_mapel(data)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_copy"),
                  color: "success"
                });
                this.getList();
                this.closeModal();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => (this.loadingSubmit = false));
        }
      }
    }
  }
};
</script>
