<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters align="center">
      <FilterSchoolYear
        v-if="checkPrivilages('ELR07')"
        :withInitial="true"
        :dataFilter="dataFilter"
        notMultiple
        @handler="getFilter"
      />
      <!-- <div class="font-weight-bold subtitle-2 ml-2">
        {{ total }} {{ $t("routes.elearning") }}
      </div> -->

      <v-spacer />

      <v-btn
        v-if="checkPrivilages('ELR01')"
        :loading="loadingDownloadExcel"
        :disabled="dataTable.length == 0"
        class="gradient-primary caption"
        dark
        depressed
        ><download-excel
          :fetch="downloadExcel"
          :fields="fields"
          :name="excelFileName()"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
            $t("app.download")
          }}</span>
          <v-icon>mdi-cloud-download</v-icon>
        </download-excel>
      </v-btn>
    </v-row>

    <v-divider v-if="checkPrivilages('ELR07')" class="my-4"></v-divider>

    <v-data-table
      :headers="
        $store.getters.g_role_type === 'STUDENT'
          ? tableHeadersStudent
          : tableHeaders
      "
      :items="dataTable"
      :loading="loading"
      class="elevation-0"
    >
      <template v-slot:item.lesson="{ item }">
        {{ item.lesson }} {{ item.sub }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          icon
          small
          dark
          class="primary"
          :disabled="loading"
          @click="selectLesson(item)"
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-if="checkPrivilages('ELR02')"
          icon
          small
          dark
          class="primary mx-2"
          :disabled="loading"
          @click="selectLesson(item, 'copy')"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <CopyFromMapel
      :dialog="modalCopyStatus"
      :closeModal="() => (modalCopyStatus = false)"
      :lessonId="selectedLessonId"
      :lesson="selectedLesson"
      :selectedLessonMaster="selectedLessonMaster"
      :filter="filter"
      :filterList="filterList"
      :getList="initData"
    />
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import { checkPrivilages } from "@/utils/privilages";
import { get_school_year_list, get_grade_list } from "@/api/admin/schoolClass";
import { getSubjectsList } from "@/api/admin/academic/subjects";
import CopyFromMapel from "./components/CopyFromMapel";
import FilterSchoolYear from "../../components/filterSchooYear";

export default {
  metaInfo: {
    title: i18n.t("routes.elearning"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    CopyFromMapel,
    FilterSchoolYear
  },
  data() {
    return {
      modalCopyStatus: false,
      selectedLesson: null,
      selectedLessonId: null,
      selectedLessonMaster: null,
      selected: [],
      loading: false,
      loadingDownloadExcel: false,
      total: 0,
      length: 0,
      dataTable: [],
      dataTableTemp: [],
      fields: {
        [i18n.t("core_competencies.table.subject")]: {
          field: "id_lesson",
          callback: value => {
            const getSubLesson = this.dataTableTemp.find(
              r => r.id_lesson == value
            );
            if (getSubLesson) {
              const lesson = `${getSubLesson.lesson} ${
                getSubLesson.sub ? getSubLesson.sub : ""
              }`;
              return lesson;
            } else return getSubLesson ? getSubLesson.lesson : "";
          }
        },
        [i18n.t("elearning.table.published")]: "published_elearning",
        [i18n.t("elearning.table.unpublish")]: "unpublished_elearning"
      },
      tableHeadersStudent: [
        {
          text: i18n.t("elearning.table.subject"),
          align: "left",
          value: "lesson"
        },
        {
          text: i18n.t("elearning.table.total"),
          value: "published_elearning"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      tableHeaders: [
        {
          text: i18n.t("elearning.table.subject"),
          align: "left",
          value: "lesson"
        },
        {
          text: i18n.t("elearning.table.published"),
          value: "published_elearning"
        },
        {
          text: i18n.t("elearning.table.unpublish"),
          value: "unpublished_elearning"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      filter: {
        school_year: null,
        grade: null,
        class: null
      },
      filterList: {
        schoolYearList: [],
        gradeList: [],
        classList: []
      },
      loadingFilter: {
        schoolYear: false,
        grade: false,
        class: false
      },
      dataFilter: {},
      getFilterDesc: {},
      isStudent: this.$store.getters.g_role_type === "STUDENT"
    };
  },
  mounted() {
    this.getMasterList();
    this.dataFilter = this.$route.query;
  },
  methods: {
    excelFileName() {
      return `${i18n.t("routes.elearning")}.xls`;
    },
    getFilter(res) {
      (this.filter = {
        school_year: res.filter.school_year,
        grade: res.filter.grade,
        class: res.filter.class ? res.filter.class : ""
      }),
        (this.filterList = res.master);
      if (res.type === "select_class") this.initData();
    },
    checkPrivilages: name => checkPrivilages(name),
    async downloadExcel() {
      this.dataTableTemp = [];
      this.loadingDownloadExcel = true;
      // const query = JSON.parse(JSON.stringify(this.queryData));
      // query.limit = this.total;
      // query.page = 1;

      const res = await getSubjectsList({
        filter: {
          classroom:
            typeof this.filter.class == "number" ? [this.filter.class] : [],
          school_year: [this.filter.school_year]
        }
      });
      this.loadingDownloadExcel = false;

      if (res.data.code) {
        this.dataTableTemp = res.data.data;
        if (res.data.length == 0) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("app.data_not_found"),
            color: "error"
          });
        } else return res.data.data;
      }
    },
    async getMasterList() {
      this.loadingFilter.schoolYear = true;
      this.loadingFilter.grade = true;

      const rSchoolYear = await get_school_year_list();
      const rGrade = await get_grade_list();
      this.filterList.schoolYearList = rSchoolYear.data.data;
      this.filterList.gradeList = rGrade.data.data;

      const query = this.$route.query;
      if (query.school_year && query.grade) {
        this.filter.school_year = Number(query.school_year);
        this.filter.grade = Number(query.grade);
        this.initData();
      }

      if (this.isStudent) {
        const g_user = this.$store.getters.g_user;
        const body = {
          class: g_user.student.class,
          school_year: this.filterList.schoolYearList.find(
            item => item.status === 1
          ).id
        };
        this.filter.class = body.class;
        this.filter.school_year = body.school_year;
        this.getData(body);
      }

      this.loadingFilter.schoolYear = false;
      this.loadingFilter.grade = false;
    },
    getData(item) {
      this.loading = true;
      let body = {
        filter: {
          classroom: [this.filter.class],
          school_year: this.filter.school_year
        }
      };
      if (this.isStudent) {
        body = {
          filter: {
            classroom: [item.class],
            school_year: item.school_year
          }
        };
      }
      getSubjectsList(body)
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data;
            this.total = res.data.data.length;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    initData() {
      this.dataTable = [];
      if (
        typeof this.filter.class == "number" &&
        this.filter.school_year.length > 0
      ) {
        this.getData();
      }
    },
    selectLesson(data, type) {
      this.selectedLessonId = data.id_lesson;
      this.selectedLessonMaster = data.lesson_master;
      this.selectedLesson = `${data.lesson} ${data.sub ? data.sub : ""}`;
      const filter = this.filter;
      const grade = this.isStudent ? data.id_grade : filter.grade;
      this.loading = true;
      setTimeout(() => {
        if (type == "copy") {
          this.modalCopyStatus = true;
        } else {
          this.$router.push(
            `e-learning/${filter.school_year}/${grade}/${filter.class}/${data.id_lesson}`
          );
        }
        this.loading = false;
      }, 1000);
    }
  }
};
</script>
